import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const TheShoyaright = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Shoyaright! Volume 3" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div id="shoyarightChomsky" />
      <div id="lastCat" className="categories">
        MUSIC POLITICS CINEMA ART TELEVISION CUISINE TECHNOLOGY SPORTS NATURE
      </div>
      <div className="articleList">
        <p>
          <Link to="/shoyaright/vol3/me-you-oldie-goldie">
            Me & You & Oldie & Goldie
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/shoyotathon-23">Shoyotathon '23</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/the-pupil">The Pupil</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/shahada99">
            لَا إِلَٰهَ إِلَّا ٱللَّٰهُ
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/rare-sayings">Rare Sayings</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/shoya-twitter">
            Shoya Inc. to Sell Twitter
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/crypto-butterfly">Crypto Butterfly</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/is-cool-a-virtue">Is Cool A Virtue?</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/logophilia">Logophilia Rising</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol3/us-russia">
            Should the U.S. Go to War with Russia?
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/">Volume 2</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/">Volume 1</Link>
        </p>
      </div>
      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020 - &infin;</p>
      </div>
    </div>
  )
}

export default TheShoyaright
